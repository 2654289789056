import httpFlexbenService from "@/http/http-flexben.js";
import ClientHeader from "@/components/fragments/ClientHeader";
import ClientBenefits from "./partials/ClientBenefits";
import ClientGeneralSettings from "./partials/ClientGeneralSettings";
import ClientConfirmModal from "./modals/ClientConfirmModal";

export default {
  components: {
    ClientHeader,
    ClientConfirmModal,
    ClientBenefits,
    ClientGeneralSettings,
  },
  data() {
    return {
      httpService: httpFlexbenService.clients,
      isAdmin: false, //change to dynamic

      expandBenefit: false,
      clientId: null,
      initialLoading: false,
      loading: true,
      loaderError: false,
      clientData: {},
      globalVariables: {},
      nonLegislatedBenefits: [],
      hasEdited: false,
      isSubmitted: false,
      formData: {
        legislated: [],
        not_legislated: [],
        pp3_benefits:[],
        form_options: [],
        client_settings: {
          cam_budget_taxation: false,
          legislated_gross_calculation: false,
          gross_budget_taxation: false,
          annual_budget_reset: false,
          use_telework_budget: false,
          last_monthly_order_day: null,
          ppp3_order_day: null,
          medical_insurance_order_day: null,
          banner_image_url: null,
          company_module_permissions: [],
          module_permissions_editable: false,
          top_up_live: true,
          type: null,
          type_code: null,
          non_legislated_net_taxes_calculate: false,
          invoice: {
            enable: false,
            invoice_day: null,
            invoice_type: null,
            subscription_included: false,
            only_active_employees: false,
            benefit_types: []
          }
        },
        benefit_translations: {},
        bannerImage: null
      },
      isFormEdited: false,
      formSuccess: false,
      rerender: true,

      openModal: false,
      modalAction: '',
      endMessage: '',
    }
  },
  watch: {
    formData: {
      handler(val) {
        this.isFormEdited = true;
      },
      deep: true
    },
    initialLoading: {
      handler(val) {
        this.isFormEdited = false;
      }
    },
  },
  created() {
    let userRole = this.flxUserLevel().userLevel;
    const employment = this.$store.getters.flx_activeEmployment;
    if (userRole === 'client' && employment != null) {
      this.clientId = parseInt(employment.company_id);
      this.isAdmin = false;
    } else if (userRole === 'admin' && typeof this.$route.params.id !== "undefined") {
      this.clientId = parseInt(this.$route.params.id);
      this.isAdmin = true;
    }

    if (this.clientId !== null) {
      this._getClientData()
    } else {
      this.goBack();
    }
  },
  methods: {
    goBack() {
      if (this.isFormEdited) {
        this.modalAction = 'go_back';
        this.openModal = true;
      } else {
        if (this.isAdmin) {
          this.loading = true;
          window.location = '/multibeneficii/clients';
        } else {
          if (window.history.length) {
            window.history.back();
          } else {
            window.location = '/multibeneficii/dashboard';
          }
        }
      }
    },
    onOver() {
      this.$refs.dropdown.visible = true;
    },
    runPpp3Command() {
      this.modalAction = 'run_ppp3_command';
      this.openModal = true;
    },
    submitForm() {
      this.modalAction = 'form_submit';
      this.openModal = true;
    },
    confirmAction(action) {
      if (action === 'form_submit') {
        this.modalAction = '';
        this.openModal = false;
        this.loading = true;
        this._postForm();
      }
      if (action === 'run_ppp3_command') {
        this.modalAction = '';
        this.openModal = false;
        this.loading = true;
        this._runPPP3Comand();
      }
      if (action === 'go_back') {
        window.location = '/multibeneficii/clients';
      }
      if (action === 'close_modal') {
        this.closeModal();
      }
    },
    closeModal() {
      this.openModal = false;
      if (this.modalAction === 'end') {
        this._getClientData();
      }
      this.modalAction = '';
    },
    toggleLoading(loading, loaderError = false) {
      this.loading = loading;
      this.loaderError = loaderError;
    },
    _postForm() {
      if (this._validateFormPost()) {
        this.loading = false;
        return;
      }

      this.formSuccess = false;
      this.httpService
        .updateClient(this.clientId, this._prepareForm())
        .then((data) => {
          this.loading = false;
          this.formSuccess = true;
          this.modalAction = 'end';
          this.endMessage = this.$t('flexben.clients.modal.update_success');
          this.openModal = true;
          this.clientData.cam_budget_taxation = this.formData.client_settings.cam_budget_taxation;
          this.clientData.gross_budget_taxation = this.formData.client_settings.gross_budget_taxation;
          this.clientData.annual_budget_reset = this.formData.client_settings.annual_budget_reset;
          this.clientData.top_up_live = this.formData.client_settings.top_up_live;
          this.clientData.use_telework_budget = this.formData.client_settings.use_telework_budget;
          this.clientData.banner_image_url = this.formData.client_settings.banner_image;
          this.clientData.cash_out_last_order_day = this.formData.client_settings.cash_out_last_order_day;
          this.clientData.ppp3_order_day = this.formData.client_settings.ppp3_order_day;
          this.clientData.medical_insurance_order_day = this.formData.client_settings.medical_insurance_order_day;
          this.clientData.invoice = this.formData.client_settings.invoice;
          this.clientData.non_legislated_net_taxes_calculate = this.formData.client_settings.non_legislated_net_taxes_calculate;
          this.clientData.cash_out_on_expiration = this.formData.client_settings.cash_out_on_expiration;
          this.clientData.cash_out_on_delete = this.formData.client_settings.cash_out_on_delete;
          this.clientData.cash_out_on_suspend = this.formData.client_settings.cash_out_on_suspend;
        })
        .catch((error) => {
          this.loading = false;
          this.formSuccess = false;
          this.modalAction = 'end';
          this.endMessage = this.$t('flexben.clients.modal.update_fail');
          this.openModal = true;
        });
    },
    _getClientData() {
      if (this.clientId !== null && this.rerender) {
        this.loading = true;
        this.initialLoading = false;
        httpFlexbenService.clients
          .loadClient(this.clientId)
          .then((result) => {
            if (!this.initialLoading) {
              this.clientData = result.data.company;
            }
            this.globalVariables = result.data.global_variables;
            this.formData.legislated = result.data.company.benefit_configurations;
            this.formData.not_legislated = result.data.non_legislated_benefits;
            this.formData.pp3_benefits = result.data.pp3_benefits;
            this.formData.client_settings.cam_budget_taxation = result.data.company.cam_budget_taxation;
            this.formData.client_settings.gross_budget_taxation = result.data.company.gross_budget_taxation;
            this.formData.client_settings.legislated_gross_calculation = result.data.company.legislated_gross_calculation;
            this.formData.client_settings.last_monthly_order_day = result.data.company.last_monthly_order_day;
            this.formData.client_settings.annual_budget_reset = result.data.company.annual_budget_reset;
            this.formData.client_settings.budget_telework_transfer_to_general = result.data.company.budget_telework_transfer_to_general;
            this.formData.client_settings.banner_image_url = result.data.company.banner_image_url;
            this.formData.client_settings.cash_out_last_order_day = result.data.company.cash_out_last_order_day;
            this.formData.client_settings.cash_out_on_expiration = result.data.company.cash_out_on_expiration;
            this.formData.client_settings.cash_out_on_suspend = result.data.company.cash_out_on_suspend;
            this.formData.client_settings.cash_out_on_delete = result.data.company.cash_out_on_delete;
            this.formData.client_settings.ppp3_order_day = result.data.company.ppp3_order_day;
            this.formData.client_settings.medical_insurance_order_day = result.data.company.medical_insurance_order_day;
            this.formData.client_settings.order_cancelation_status = result.data.company.order_cancelation_status;
            this.formData.client_settings.module_permissions_editable = result.data.company.module_permissions_editable;
            this.formData.client_settings.type = result.data.company.type;
            this.formData.client_settings.top_up_live = result.data.company.top_up_live;
            this.formData.client_settings.type_code = result.data.company.type_code;
            this.formData.client_settings.non_legislated_net_taxes_calculate = result.data.company.non_legislated_net_taxes_calculate;
            if (result.data.company.invoice_config) {
              this.formData.client_settings.invoice.enable = result.data.company.invoice_config.enable;
              this.formData.client_settings.invoice.invoice_day = result.data.company.invoice_config.invoice_day;
              this.formData.client_settings.invoice.invoice_type = result.data.company.invoice_config.invoice_type;
              this.formData.client_settings.invoice.subscription_included = result.data.company.invoice_config.subscription_included;
              this.formData.client_settings.invoice.benefit_types = result.data.company.invoice_config.benefit_types;
              this.formData.client_settings.invoice.only_active_employees = result.data.company.invoice_config.only_active_employees;
            }

            this.formData.form_options = result.data.form_options;

            this.formData.benefit_translations = result.data.benefit_translations;

            this.formData.client_settings.company_module_permissions = result.data.company.company_module_permissions;

            this.loading = false;
            this.initialLoading = true;

            this.endMessage = '';
          })
          .catch((error) => {
            if (error.response.status >= 500 && error.response.status < 600) {
              this.loaderError = true;
            }
          });
      }
    },
    _prepareForm() {
      let formData = new FormData();
      formData.append('legislated', JSON.stringify(this.formData.legislated));
      formData.append('client_settings', JSON.stringify(this.formData.client_settings));
      formData.append('benefit_translations', JSON.stringify(this.isAdmin ? this.formData.benefit_translations : {}));
      formData.append('banner_image', this.formData.bannerImage);
      return formData;
    },
    _validateFormPost() {
      let upDejunConf = null, obj;
      for (let index in this.formData.legislated) {
        obj = this.formData.legislated[index];
        if (obj.type === 'up_dejun') {
          upDejunConf = obj;
          break;
        }
      }

      if (this.formData.client_settings.invoice.invoice_day) {
        if (this.formData.client_settings.medical_insurance_order_day > this.formData.client_settings.invoice.invoice_day ||
          this.formData.client_settings.ppp3_order_day > this.formData.client_settings.invoice.invoice_day) {
          this.formSuccess = false;
          this.modalAction = 'end';
          this.endMessage = this.$t('flexben.clients.errors.order_day');
          this.openModal = true;
          this.rerender = false;
          return true;
        }
      }

      if (upDejunConf !== null) {
        if (this.formData.client_settings.last_monthly_order_day !== null &&
          parseInt(upDejunConf.configuration.last_top_up_day) > this.formData.client_settings.last_monthly_order_day) {
          this.formSuccess = false;
          this.modalAction = 'end';
          this.endMessage = this.$t('flexben.clients.errors.general_last_top_up_day_greater_then_up_dejun');
          this.openModal = true;
          this.rerender = false;
          return true;
        }
        if (upDejunConf.configuration.top_up_fixed_values.enable) {
          if (upDejunConf.configuration.top_up_fixed_values.values.length === 0) {
            this.formSuccess = false;
            this.modalAction = 'end';
            this.endMessage = this.$t('flexben.clients.errors.up_dejun_fixed_values_min_1');
            this.openModal = true;
            this.rerender = false;
            return true;
          } else {
            let emptyValues = upDejunConf.configuration.top_up_fixed_values.values.filter(function (value) {
              return (!value ||
                value < upDejunConf.benefit.data.values.daily_ticket_min_value.value ||
                upDejunConf.benefit.data.values.daily_ticket_max_value.value < value);
            });
            if (emptyValues.length > 0) {
              this.formSuccess = false;
              this.modalAction = 'end';
              this.endMessage = this.$t('flexben.clients.errors.up_dejun_fixed_values_invalid_values');
              this.openModal = true;
              this.rerender = false;
              return true;
            }
          }
        }
        if (upDejunConf.configuration.top_up_set_ticket_nr.enable && (
          upDejunConf.configuration.top_up_set_ticket_nr.ticket_value === null ||
          upDejunConf.configuration.top_up_set_ticket_nr.ticket_value < upDejunConf.benefit.data.values.daily_ticket_min_value.value ||
          upDejunConf.configuration.top_up_set_ticket_nr.ticket_value > upDejunConf.benefit.data.values.daily_ticket_max_value.value
        )) {
          this.formSuccess = false;
          this.modalAction = 'end';
          this.endMessage = this.$t('flexben.clients.errors.up_dejun_fixed_values_invalid_values_single');
          this.openModal = true;
          this.rerender = false;
          return true;
        }
      }
      this.rerender = true;
      return false;
    },
    _runPPP3Comand() {
      this.loading = true;

      this.httpService.runPpp3Command(this.clientId)
          .then((data) => {
            this.loading = false;

            this.formSuccess = true;
            this.modalAction = 'end';
            this.endMessage = this.$t('flexben.clients.modal.ppp3_command_success');
            this.openModal = true;
          })
          .catch((error) => {
            this.loading = false;

            this.formSuccess = false;
            this.modalAction = 'end';
            this.endMessage = this.$t('flexben.clients.modal.ppp3_command_fail');
            this.openModal = true;
          });
    }
  }
};
