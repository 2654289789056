<template>
  <div class="mt-3">
    <div class="text-28 text-bold">
      {{$t("flexben.clients.client_general_config_titles")}}
    </div>
    <div class="container-bg-white mt-2">
      <div class="row px-3 pt-3">
        <div class="col-12 col-md-6 col-lg-4">
          <label for="last_order_days" class="col-form-label pt-0 cursor-pointer">
            {{$t("flexben.clients.partial.last_order_days")}}
          </label>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 form-group mb-1" :class="{'pb-3':!isAdmin}">
        <div class="d-flex flex-row align-items-center">
          <select class="form-control" id="last_order_days" v-model="generalClientSettings.last_monthly_order_day">
            <option v-for="(option, index) of last_order_days"
                    :value="option.val" :key="index">{{ option.text }}
            </option>
          </select>

          <span id="top_up_dejun_information"
                class="d-inline-block flexben-tooltip-icon ml-2 text-bold">i</span>
          <b-tooltip :target="'top_up_dejun_information'" variant="flexben" placement="topleft">
            <div v-html="$t('flexben.clients.errors.general_last_top_up_day_greater_then_up_dejun')"
                 class="text-left"></div>
          </b-tooltip>
        </div>
      </div>

      <div class="row px-3 pt-3">
        <div class="col-12 col-md-6 col-lg-4">
          <label for="ppp3_order_day" class="col-form-label pt-0 cursor-pointer">
            {{$t("flexben.clients.partial.ppp3_order_day")}}
          </label>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 form-group mb-1" :class="{'pb-3':!isAdmin}">
        <div class="d-flex flex-row align-items-center">
          <select class="form-control" id="ppp3_order_day" v-model="generalClientSettings.ppp3_order_day">
            <option v-for="(option, index) of last_order_days"
                    :value="option.val" :key="index">{{ option.text }}
            </option>
          </select>
        </div>
      </div>

      <div class="row px-3 pt-3">
        <div class="col-12 col-md-6 col-lg-4">
          <label for="medical_insurance_order_day" class="col-form-label pt-0 cursor-pointer">
            {{$t("flexben.clients.partial.medical_insurance_order_day")}}
          </label>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 form-group mb-1" :class="{'pb-3':!isAdmin}">
        <div class="d-flex flex-row align-items-center">
          <select class="form-control" id="medical_insurance_order_day" v-model="generalClientSettings.medical_insurance_order_day">
            <option v-for="(option, index) of last_order_days"
                    :value="option.val" :key="index">{{ option.text }}
            </option>
          </select>
        </div>
      </div>

      <div class="row px-3 pt-3">
        <div class="col-12 col-md-6 col-lg-4">
          <label for="order_cancelation_status" class="col-form-label pt-0 cursor-pointer">
            {{$t("flexben.clients.partial.order_cancelation_status")}}
          </label>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 form-group mb-1" :class="{'pb-3':!isAdmin}">
        <div class="d-flex flex-row align-items-center">
          <select v-if="isAdmin" class="form-control" id="order_cancelation_status"
                  v-model="generalClientSettings.order_cancelation_status">
            <option v-for="(option, index) of formOptions.order_cancelation_status_options"
                    :value="option" :key="index">{{ $t('flexben.requests.summary.status.' + option)}}
            </option>

          </select>
          <input v-else :value="$t('flexben.requests.summary.status.' + generalClientSettings.order_cancelation_status)"
                 class="form-control" disabled>
        </div>
      </div>

      <div class="row px-3 pb-3" v-if="isAdmin">
        <div class="col-12 d-flex">
          <label for="cam_budget_taxation" class="col-form-label mr-4 p-0 cursor-pointer">
            {{$t("flexben.clients.partial.cam_budget_taxation")}}
          </label>
          <b-form-checkbox id="cam_budget_taxation" class="d-inline-block cursor-pointer"
                           switch
                           v-model="generalClientSettings.cam_budget_taxation">
          </b-form-checkbox>
        </div>
        <div class="col-12 d-flex mt-2">
          <label for="gross_budget_taxation" class="col-form-label mr-4 p-0">
            {{$t("flexben.clients.partial.gross_budget_taxation")}}
          </label>
          <b-form-checkbox id="gross_budget_taxation" class="d-inline-block"
                           switch
                           v-model="generalClientSettings.gross_budget_taxation">
          </b-form-checkbox>
        </div>
        <div class="col-12 d-flex mt-2">
          <label for="legislated_gross_calculation" class="col-form-label mr-4 p-0 cursor-pointer">
            {{$t("flexben.clients.partial.legislated_gross_calculation")}}
          </label>
          <b-form-checkbox id="legislated_gross_calculation" class="d-inline-block cursor-pointer"
                           :disabled="!generalClientSettings.gross_budget_taxation"
                           switch
                           v-model="generalClientSettings.legislated_gross_calculation">
          </b-form-checkbox>
        </div>

        <div class="col-12 d-flex mt-2">
          <label for="annual_budget_reset" class="col-form-label mr-4 p-0 cursor-pointer">
            {{$t("flexben.clients.partial.annual_budget_reset")}}
          </label>
          <b-form-checkbox id="annual_budget_reset" class="d-inline-block cursor-pointer"
                           switch
                           v-model="generalClientSettings.annual_budget_reset">
          </b-form-checkbox>
        </div>

        <div class="col-12 d-flex mt-2">
          <label for="budget_telework_transfer_to_general" class="col-form-label mr-4 p-0 cursor-pointer">
            {{$t("flexben.clients.partial.budget_telework_transfer_to_general")}}
          </label>
          <b-form-checkbox id="budget_telework_transfer_to_general" class="d-inline-block cursor-pointer"
                           switch
                           v-model="generalClientSettings.budget_telework_transfer_to_general">
          </b-form-checkbox>
        </div>
        <div class="col-12 d-flex mt-2">
          <label for="non_legislated_net_taxes_calculate" class="col-form-label mr-4 p-0 cursor-pointer">
            {{$t("flexben.clients.partial.non_legislated_net_taxes_calculate")}}
          </label>
          <b-form-checkbox id="non_legislated_net_taxes_calculate" class="d-inline-block cursor-pointer"
                           switch
                           v-model="generalClientSettings.non_legislated_net_taxes_calculate">
          </b-form-checkbox>
          <div class="ml-2">
            <span id="non_legislated_net_taxes_calculate_tooltip"
                  class="d-inline-block flexben-tooltip-icon ml-2 text-bold">i</span>
            <b-tooltip :target="'non_legislated_net_taxes_calculate_tooltip'" variant="flexben" placement="topleft">
              <div v-html="$t('flexben.clients.partial.partial_tooltips.non_legislated_net_taxes_calculate_tooltip')"
                   class="text-left"></div>
            </b-tooltip>
          </div>
        </div>
        <div class="col-12 d-flex mt-2">
          <label for="top_up_live" class="col-form-label mr-4 p-0 cursor-pointer">
            {{$t("flexben.clients.partial.top_up_live")}}
          </label>
          <b-form-checkbox id="top_up_live" class="d-inline-block cursor-pointer"
                           :disabled="!isAdmin"
                           switch
                           v-model="generalClientSettings.top_up_live">
          </b-form-checkbox>
        </div>
        <div class="col-12 col-md-6 col-lg-4 form-group mb-1 mt-2">
          <label for="cash_out_last_order_day" class="col-form-label mr-4 p-0">
            {{$t("flexben.clients.partial.cash_out_last_order_day")}}
          </label>
          <div>
            <select v-model="generalClientSettings.cash_out_last_order_day"
                    id="cash_out_last_order_day" class="form-control">
              <option v-for="day of generateRange(5, 25)" :value="day" :key="day">{{ day }}</option>
            </select>
          </div>
        </div>

        <div class="col-12 d-flex mt-2">
          <label for="cash_out_on_expiration" class="col-form-label mr-4 p-0 cursor-pointer">
            {{$t("flexben.clients.partial.cash_out_on_expiration")}}
          </label>
          <b-form-checkbox id="cash_out_on_expiration" class="d-inline-block cursor-pointer"
                           :disabled="!isAdmin"
                           switch
                           v-model="generalClientSettings.cash_out_on_expiration">
          </b-form-checkbox>
        </div>

        <div class="col-12 d-flex mt-2">
          <label for="cash_out_on_suspend" class="col-form-label mr-4 p-0 cursor-pointer">
            {{$t("flexben.clients.partial.cash_out_on_suspend")}}
          </label>
          <b-form-checkbox id="cash_out_on_suspend" class="d-inline-block cursor-pointer"
                           :disabled="!isAdmin"
                           switch
                           v-model="generalClientSettings.cash_out_on_suspend">
          </b-form-checkbox>
        </div>

        <div class="col-12 d-flex mt-2">
          <label for="cash_out_on_expiration" class="col-form-label mr-4 p-0 cursor-pointer">
            {{$t("flexben.clients.partial.cash_out_on_delete")}}
          </label>
          <b-form-checkbox id="cash_out_on_delete" class="d-inline-block cursor-pointer"
                           :disabled="!isAdmin"
                           switch
                           v-model="generalClientSettings.cash_out_on_delete">
          </b-form-checkbox>
        </div>
      </div>

      <div class="row px-3 pb-3 mt-2">
        <hr>
        <div class="text-22 text-bold">
          {{$t("flexben.clients.invoice.title")}}
        </div>
      </div>
      <div class="row px-3 pb-3">
        <div class="col-12 col-md-6 col-lg-4 form-group mb-1 mt-2 d-flex flex-column">
          <label for="non_legislated_benefit_invoice_enabled" class="text-bold col-form-label mr-4 p-0 cursor-pointer text-16 text-bold">
            {{$t("flexben.clients.invoice.enable")}}
          </label>
          <div class="d-flex mt-3">
            <b-form-checkbox id="non_legislated_benefit_invoice_enabled" class="cursor-pointer ml-2 mr-3"
                             switch
                             :disabled="!isAdmin || !client.can_be_invoiced"
                             v-model="generalClientSettings.invoice.enable"
            >
            </b-form-checkbox>
          </div>
        </div>

        <div v-if="isAdmin" class="col-12 col-md-6 col-lg-4 form-group mb-1 mt-2">
          <label for="non_legislated_benefit_subscription_included" class="col-form-label mr-4 p-0 text-16 text-bold">
            {{$t("flexben.clients.invoice.subscription_type_included")}}
          </label>
          <div class="d-flex mt-3">
            <b-form-checkbox id="non_legislated_benefit_subscription_included" class="cursor-pointer ml-2 mr-3"
                             switch
                             :disabled="!generalClientSettings.invoice.enable"
                             v-model="generalClientSettings.invoice.subscription_included">
            </b-form-checkbox>
          </div>
        </div>

        <div v-if="isAdmin && client.license_type===1" class="col-12 col-md-6 col-lg-4 form-group mb-1 mt-2">
          <span id="invoice_only_employees"
                class="d-inline-block flexben-tooltip-icon ml-2 text-bold">i</span>
          <b-tooltip :target="'invoice_only_employees'" variant="flexben" placement="topleft">
            <div v-html="$t('flexben.clients.invoice.only_active_employees_description')"
                 class="text-left"></div>
          </b-tooltip>

          <label for="only_active_employees" class="col-form-label mr-4 p-0 text-16 text-bold">
            {{$t("flexben.clients.invoice.only_active_employees")}}
          </label>
          <div class="d-flex mt-3">
            <b-form-checkbox id="only_active_employees" class="cursor-pointer ml-2 mr-3"
                             switch
                             v-model="generalClientSettings.invoice.only_active_employees">
            </b-form-checkbox>
          </div>

        </div>

      </div>
      <div v-if="isAdmin" class="row px-3 pb-3">
        <div class="col-12 col-md-6 col-lg-4 form-group mb-1 mt-2">
          <label for="non_legislated_benefit_invoice_day" class="col-form-label form-fields-required mr-4 p-0 text-16 text-bold">
            {{$t("flexben.clients.invoice.invoice_day")}}
          </label>
          <div>
            <select :disabled="!generalClientSettings.invoice.enable"
                    v-model="generalClientSettings.invoice.invoice_day"
                    id="non_legislated_benefit_invoice_day" class="form-control">
              <option v-for="day of generateRange(1, 25)" :value="day" :key="day">{{ day }}</option>
            </select>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 form-group mb-1 mt-2">
          <label for="non_legislated_benefit_invoice_type" class="col-form-label form-fields-required mr-4 p-0 text-16 text-bold">
            {{$t("flexben.clients.invoice.invoice_type")}}
          </label>
          <div>
            <select :disabled="!generalClientSettings.invoice.enable"
                    v-model="generalClientSettings.invoice.invoice_type"
                    id="non_legislated_benefit_invoice_type" class="form-control">
              <option v-for="type of formOptions.invoice_types" :value="type" :key="type">
                {{$t("flexben.clients.invoice.invoice_types." + type )}}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div v-if="isAdmin" id="invoice_benefit_types">
        <div class="col-12 col-md-6 col-lg-3 form-group mb-1 mt-2">
          <label for="invoice_benefit_types" class="col-form-label form-fields-required mr-4 p-0 text-16 text-bold">
            {{$t("flexben.clients.invoice.benefit_types")}}
          </label>
          <div v-for="(item, index) of generalClientSettings.invoice.benefit_types" :key="index"
               class="d-flex gri-cell mb-2 mt-2">
            <div class="col-8 col-md-8 col-lg-8 mb-2 mt-2">
              <label :for="item.type"
                     class="col-form-label mr-4 p-0 cursor-pointer fa-w-20">
                {{$t("flexben.clients.benefit_types." + item.type)}}
              </label>
            </div>
            <div class="col-4 col-md-4 col-lg-4 mt-2 mr-0">
              <b-form-checkbox :id="item.type"
                               class="mx-3"
                               switch
                               :disabled="!generalClientSettings.invoice.enable"
                               v-model="item.status">
              </b-form-checkbox>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="row px-3 pb-3">
        <div class="col-12 d-flex align-items-center">
          <label for="banner_image" class="col-form-label mr-4 p-0 text-24">
            {{$t("flexben.clients.partial.banner")}}
          </label>
          <div class="col-12 text-danger"
               :class="{'d-none':bannerImageError===null}">{{ bannerImageError }}
          </div>
        </div>
        <div class="col-12 d-flex justify-content-center align-items-start mt-3 mb-3">
          <div class="image-container-1000 d-flex cursor-pointer d-flex align-items-center"
               @click="$refs.bannerImage.click()">
            <div class="image-changed w-100" v-if="image_changed">{{$t("flexben.general.image_changed")}}</div>
            <img class="img-fluid cursor-pointer upload-image"
                 :class="{'img-border-danger':(Object.keys(errors_bag).includes('image'))}"
                 :src="(bannerImage !== null) ? image_preview : generalClientSettings.banner_image_url"/>
            <div class="upload-image-middle-container mx-auto">
              <div class="upload-image-text">{{$t("flexben.general.upload_image")}}</div>
            </div>
            <input type="file" class="hide" ref="bannerImage" accept=".png,.jpeg,.jpg,.svg,.jfif"
                   id="banner_image"
                   @change="setImageFile($event)">
          </div>
        </div>
      </div>
    </div>
    <div v-if="isAdmin" class="text-28 text-bold mt-3 mb-2">{{$t("flexben.clients.module_permission")}}</div>

    <div v-if="isAdmin" class="container-bg-white mt-2">
      <div class="row px-3 pt-3">
        <div class="col-12 col-md-6">
          <div v-for="(item, index) of generalClientSettings.company_module_permissions" :key="index"
               class="d-flex" :class="{'my-3':index!==0}">
            <label :for="item.module_permission.code"
                   class="col-form-label mr-4 p-0 cursor-pointer">
              {{ item.module_permission.name[locale] }}:
            </label>
            <div class="d-flex">
              <span>{{ $t('flexben.general.no') }}</span>
              <b-form-checkbox :id="item.module_permission.code"
                               class="mx-3"
                               switch
                               :disabled="!generalClientSettings.module_permissions_editable"
                               v-model="item.status">
              </b-form-checkbox>
              <span>{{ $t('flexben.general.yes') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BFormCheckbox, BTooltip} from "bootstrap-vue";
import i18n from "@/plugins/language/i18n";

export default {
  components: {
    "b-form-checkbox": BFormCheckbox,
    "b-tooltip": BTooltip,
  },
  name: "ClientGeneralSettings",
  props: {
    isAdmin: {
      type: Boolean,
      required: true,
      default: false
    },
    client: {
      type: Object,
      required: true
    },
    formOptions: {
      order_cancelation_status_options: [],
      invoice_types: [],
      benefit_types: []
    },
    generalClientSettings: {
      type: Object,
      required: true,
      default: function () {
        return {
          cam_budget_taxation: false,
          legislated_gross_calculation: false,
          gross_budget_taxation: false,
          annual_budget_reset: false,
          use_telework_budget: false,
          last_monthly_order_day: null,
          ppp3_order_day: null,
          medical_insurance_order_day: null,
          banner_image_url: null,
          company_module_permissions: [],
          module_permissions_editable: false,
          top_up_live: false,
          type: null,
          type_code: null,
          non_legislated_net_taxes_calculate: false,
          invoice: {
            enable: false,
            invoice_day: null,
            invoice_type: null,
            subscription_included: false,
            only_active_employees: false,
            benefit_types: []
          }
        };
      }
    },
    bannerImage: {
      default: null
    },
    bannerImageUrl: {
      default: null
    },
  },
  data: function () {
    return {
      image_changed: false,
      image_preview: null,
      bannerImageError: null,
      errors_bag: {},
      locale: i18n.locale,
    };
  },
  computed: {
    last_order_days: function () {
      let options = [
        {val: null, text: this.$t('flexben.general.disabled')}
      ];
      let option;
      for (let i = 1; i <= 31; i++) {
        option = {val: i, text: i};
        options.push(option);
      }
      option = null;

      return options;
    }
  },
  watch: {
    'generalClientSettings.banner_image_url': function (newVal, oldVal) {
      this.image_changed = false;
    },
    'generalClientSettings.gross_budget_taxation': function (val) {
      if (!val) {
        this.generalClientSettings.legislated_gross_calculation = false;
      }
    },
    deep: true,
  },
  methods: {
    setImageFile() {
      this.errors_bag = {};
      if (this.$refs.bannerImage.accept.indexOf(this.$refs.bannerImage.files[0].type.split('/')[1]) > 0) {
        let reader = new FileReader();
        reader.readAsDataURL(this.$refs.bannerImage.files[0]);
        reader.onload = evt => {
          let img = new Image();
          img.onload = () => {
            if (img.width !== 1018 || img.height !== 166) {
              this.bannerImageError = this.$t('flexben.formErrorReasons.image_1018_166');
            } else {
              this.$parent.formData.bannerImage = this.$refs.bannerImage.files[0];
              this.bannerImageError = null;
              this.image_changed = true;
              if (typeof this.$parent.formData.bannerImage !== "undefined") {
                this.image_preview = URL.createObjectURL(this.$parent.formData.bannerImage);
              }
            }
          }
          img.src = evt.target.result;
        }
      } else {
        this.errors_bag = {image: 'image_type_supported'}
      }
    }
  },
  created() {
    if (this.generalClientSettings.invoice.benefit_types.length === 0) {
      this.formOptions.benefit_types.forEach(benefitType => {
        this.generalClientSettings.invoice.benefit_types.push({type: benefitType, status : false});
      })
    }
  }
}
</script>
