<template>
  <b-tab :title="$t('flexben.beneficiaries.requests')" :active="activeTab === 'requests'" :key="'requests'"
         class="container-bg-white">
    <div class="row px-3 pb-2">
      <div class="col-12">
        <h3 class="mb-0 py-1 pl-0">{{$t("flexben.general.entries")}}</h3>
      </div>
      <div class="col-12 mt-2 sub-tab-table">
        <div class="table-responsive hover-table">
          <table class="table no-min-width">
            <thead class="text-center">
            <tr>
              <td>{{$t("flexben.beneficiaries.request.number")}}</td>
              <td>{{$t("flexben.beneficiaries.request.placed_on")}}</td>
              <td>{{$t("flexben.general.type")}}</td>
              <td>{{$t("flexben.clients.partial.benefit")}}</td>
              <td>{{$t("flexben.beneficiaries.request.amount")}}</td>
              <td>{{ $t("flexben.requests.table.header.taxes") }}</td>
              <td v-if="tableData.company && tableData.company.cam_budget_taxation">{{ $t("flexben.beneficiary_pages.requests.table.cam_value") }}</td>
              <td>{{$t("flexben.beneficiaries.request.status")}}</td>
              <td>{{$t("flexben.beneficiaries.request.order_number")}}</td>
              <td>{{$t("flexben.beneficiaries.request.order_placed_on")}}</td>
              <td>{{$t("flexben.beneficiaries.request.order_status")}}</td>
              <td v-if="isAdmin">{{$t("flexben.beneficiaries.request.reject_order")}}</td>
            </tr>
            </thead>
            <tbody class="text-center">
            <template v-if="tableData.data.length >0">
              <tr v-for="(item, index) in tableData.data" :key="index"
                  class="trhover">
                <td>{{item.id}}</td>
                <td>{{moment(item.placed_at, 'DD/MM/YYYY HH:mm')}}</td>
                <td>
                  <img src="@/assets/images/flexben/order/topUp.svg" alt="Top up" v-if="item.type === 'top_up'"/>
                  <img src="@/assets/images/flexben/order/cardRequest.svg" alt="Card request" v-else/>
                </td>
                <td><span :style="{backgroundColor: item.benefit.color}" class="color-bullet"></span> {{
                  item.benefit.name }}
                </td>
                <td>{{ (item.total_value > 0) ? item.total_value+' Lei' : '-' }}</td>
                <td>{{ (item.total_value > 0) ?
                  ((item.total_value_cas+item.total_value_cass+item.total_value_tax).toFixed(2)+' Lei') : '-'}}
                </td>
                <td v-if="tableData.company && tableData.company.cam_budget_taxation">{{ (item.total_value > 0) ? item.value_cam.toFixed(2)+' Lei' : '-' }}</td>
                <td>
                  <span class="beneficiary-request-status"
                          :class="'flx-'+item.status">
                        {{ $t('flexben.requests.summary.status.' + item.status) }}
                  </span>

                  <span :id="'reject_tooltip_'+item.id" v-if="['rejected', 'validation_failed'].includes(item.status)"
                        class="d-inline-block flexben-tooltip-icon flexben-tooltip-icon-red  ml-1">i</span>

                  <b-tooltip :target="'reject_tooltip_' + item.id"
                             variant="flexben"
                             placement="topleft"
                             triggers="hover"
                             v-if="['rejected', 'validation_failed'].includes(item.status)">
                    {{ item.status === 'validation_failed' ? getValidationFailedMessage(item.reject_reason) : getRejectMessage(item.reject_reason) }}

                  </b-tooltip>

                </td>
                <td>{{ (item.order_id!==null) ? item.order_number :'-'}}</td>
                <td>{{ (item.order_id!==null) ? moment(item.orders_placed_at, 'DD/MM/YYYY hh:mm') :'-'}}</td>
                <td>
                  <span class="benefit-status m-0"
                        v-if="item.order_id!==null"
                        :class="'benefit-status-'+item.order_status">{{ $t('flexben.general.status_type.' + item.order_status) }}</span>
                  {{ (item.order_id===null) ? '-' :''}}
                </td>
                <td>
                  <b-button
                    v-if="item.can_be_rejected"
                    v-b-modal="'reject-request-modal'"
                    @click="prepareRejectDataModal(item)"
                    class="btn text-danger d-flex align-content-center"
                    variant="none">
                    <font-awesome-icon icon="ban" class="mr-1 mt-auto mb-auto"/>
                    {{ $t("flexben.requests.btn.reject_request") }}
                  </b-button>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr class="gri-cell text-center trhover">
                <td colspan="1000" class="text-center">{{ $t("flexben.general.no_results_found") }}</td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-12 p-0">
        <listPagination
          :currentPage="tableData.current_page"
          :totalItems="tableData.total"
          :startItem="tableData.from"
          :endItem="tableData.to"
          :itemsPerPage="tableData.perPage"
          :defaultPerPage="10"
          @pageChanged="pageChanged"
          @changePerPage="changePerPage"></listPagination>
      </div>
      <b-modal id="reject-request-modal"
               centered
               :hide-header-close="true"
               headerClass="border-0 text-center"
               bodyClass="modal-body no-border background-white text-center"
               footerClass="modal-footer border-top-0"
               ref="cancelModal">
        <template v-slot:modal-header>
          <h2 class="mb-0 mx-auto text-capitalize">{{$t("flexben.requests.modal.title")}}</h2>
        </template>
        <template v-slot:default>
          <div class="row">
            <div class="col-12 text-center">
              <p>{{ $t('flexben.requests.modal.confirm_to_reject_request',
                { benefitName: rejectData.modalData.benefit.name, totalValue: rejectData.modalData.total_value }
                )
                }}</p>
            </div>
            <div class="mx-auto col-12 col-md-10 form-group mt-3 text-left">
              <label class="text-14 m-0" for="reject_reason">{{
                $t('flexben.requests.modal.field.reject_reason')
                }}</label>
              <textarea class="m-0 w-100 bg-white form-control-textarea"
                        rows="5"
                        maxlength="255"
                        id="reject_reason"
                        v-model="rejectData.reject_reason"></textarea>
            </div>
          </div>
        </template>
        <template v-slot:modal-footer>
          <div class="w-100">
            <button class="btn text-themed mx-auto mt-2" @click="handleClose()">{{
              $t('flexben.general.btn.cancel') }}
            </button>
            <button class="btn btn-themed mx-auto px-4 float-right" @click="handleOk">{{
              $t('flexben.general.btn.confirm') }}
            </button>
          </div>
        </template>
      </b-modal>
      <content-loader :loaderVisible="loading" :loaderError="loaderError"></content-loader>
    </div>

  </b-tab>
</template>

<script>
import {BTab} from "bootstrap-vue";
import moment from "moment";
import {BModal,BButton} from 'bootstrap-vue';
import httpFlexbenService from "@/http/http-flexben";

export default {
  name: "TabBeneficiaryHistoryRequests",
  components: {
    "b-tab": BTab,
    BModal,
    BButton,
  },
  props: {
    activeTab: {
      type: String,
      default: '',
      required: true
    },
    isAdmin: {
      required: true,
      default: false
    },
    tableData: {
      type: Object,
      default: function () {
        return {
          data: [],
          current_page: 1,
          to: 1,
          perPage: 1,
          lastPage: 1,
          total: 1
        };
      },
      required: true
    },
  },
  data(){
    return {
      httpService: httpFlexbenService.requests,
      rejectData: {
        modalData: {},
        reject_reason: ''
      },
      loading: false,
      loaderError: false,
    }
  },
  methods: {
    pageChanged(page) {
      this.$emit('pageChanged', page);
    },
    changePerPage(perPage) {
      this.$emit('changePerPage', perPage, 'request');
    },
    moment(date, format) {
      if (date === null) {
        return '-';
      }
      return moment(date).format(format);
    },
    handleOk() {
      this._rejectRequests();
    },
    handleClose() {
      this.$bvModal.hide('reject-request-modal');
    },
    prepareRejectDataModal(item) {
      this.rejectData.modalData = item;
      this.rejectData.reject_reason = '';
    },
    _rejectRequests() {
      this.loading = true;
      let payload = {
        ids: [this.rejectData.modalData.id],
        reject_reason: this.rejectData.reject_reason,
        from_beneficiary_table_history: true,
      };
      this.httpService.rejectRequests(payload)
        .then((response) => {
          for (let i in this.tableData.data) {
            if (this.tableData.data[i].id === this.rejectData.modalData.id) {
              this.tableData.data[i] = this.updateTableOrderRequestData(this.tableData.data[i], response.data.processed_order_requests);
            }
          }
          this.$bvModal.hide('reject-request-modal');
          this.loading = false;
        })
        .catch((error) => {
          if (500 <= error.response.status && error.response.status < 600) {
            this.loaderError = true;
          } else {
            this.loading = false;
          }
        });
      setTimeout(() => {
        this.refreshPage();
      }, 1000);
    },
    updateTableOrderRequestData(tableData, responseData) {
      const updatedOrderRequest = responseData[0];
      for (const key in updatedOrderRequest) {
        if (tableData.hasOwnProperty(key)) {
          tableData[key] = updatedOrderRequest[key];
        }
      }
      return tableData;
    },
    refreshPage() {
      window.location.reload();
    },
    getRejectMessage(obj) {
      if (obj === null || (obj.message === null || obj.message.length === 0)) {
        return this.$t('flexben.general.no_reason_specified');
      }

      return obj.message;
    },
    getValidationFailedMessage(obj){
      if (typeof obj.message == 'undefined' || obj.message == null){
        return this.$t('flexben.up_ro_validation_messages.UNKNOWN');
      }

      let message = '', description  = '';

      message = obj.message.replaceAll('.', '_');

      if (typeof obj.description != 'undefined' || obj.description != null){
        description = obj.description;
      }

      return this.$t('flexben.up_ro_validation_messages.'+message+'')+' '+description;
    },

  },
}
</script>
