<template>
  <div>
    <div v-show="!initialLoading">
      <div class="row">
        <div class="col-12 flexben-page-title">
          <h1>{{$t("flexben.requests.title_history")}}</h1>
          <p class="mb-0">{{ totalCount }} {{ $t("flexben.general.requests") }}</p>
        </div>
      </div>

      <div class="container-bg-white mt-3">
        <div class="row p-3">
          <div class="col-12 col-lg-3 mb-2 mb-xl-0">
            <img src="@/assets/images/filter.svg"/>
            <span class="ml-3">{{$t("flexben.general.filter")}}</span>
          </div>
          <div class="col-12 col-xl-9">
            <div class="row">
              <div class="col-12 col-md-4 form-group form-group-multiselect flx-multiselect-single mb-0" v-if="isAdmin">
                <label for="company" class="col-form-label pt-xl-0">{{ $t("flexben.requests.filter.field.client")
                  }}</label>
                <multiselect :multiple="false"
                             :showLabels="false"
                             :class="'from-control'"
                             track-by="value"
                             label="name"
                             id="company"
                             :closeOnSelect="true"
                             :allow-empty="false"
                             :placeholder="$t('flexben.general.search')"
                             v-model="selectedCompany" :options="companySelectOptions">
                  <template slot="noResult">{{$t('flexben.general.no_results_found')}}</template>
                </multiselect>
              </div>
              <div class="col-12 col-md-4 form-group mb-0">
                <label for="beneficiary" class="col-form-label pt-xl-0">{{
                  $t("flexben.requests.filter.field.beneficiary") }}</label>
                <select class="form-control w-100" id="beneficiary" :disabled="(params.company === null)"
                        v-model="params.beneficiary" @change="filterValues()">
                  <option :value="null">-</option>
                  <option v-for="(entry, id) in filter_options.beneficiaries" :key="id" :value="id">
                    {{ entry }}
                  </option>
                </select>
              </div>
              <div class="col-12 col-md-4 form-group mb-0">
                <label for="request_type" class="col-form-label pt-xl-0">{{
                  $t("flexben.requests.filter.field.request_type") }}</label>
                <select class="form-control w-100" id="request_type" @change="filterValues()"
                        v-model="params.type">
                  <option :value="null">-</option>
                  <option v-for="(value, key) in filter_options.types" :key="key" :value="value">
                    {{ $t('flexben.requests.types.'+value) }}
                  </option>
                </select>
              </div>

              <div class="col-12 col-md-4 form-group mb-0 form-group-multiselect flx-multiselect-single"
                   :class="{'offset-md-4':isAdmin}">
                <label for="benefit" class="col-form-label" :class="{'pt-0':!isAdmin}">{{
                  $t("flexben.requests.filter.field.benefit") }}</label>
                <multiselect :multiple="false"
                             :showLabels="false"
                             :class="'from-control'"
                             track-by="value"
                             label="name"
                             id="benefit"
                             :closeOnSelect="true"
                             :allow-empty="false"
                             :placeholder="$t('flexben.general.search')"
                             v-model="selectedBenefit" :options="benefitOptions" :key="'benefit_select'">
                  <template slot="noResult">{{$t('flexben.general.no_results_found')}}</template>
                </multiselect>
              </div>
              <div class="col-12 col-md-4 form-group mb-0">
                <label for="request_status" class="col-form-label">{{
                  $t("flexben.general.status") }}</label>
                <select class="form-control w-100" id="request_status" @change="filterValues()"
                        v-model="params.status">
                  <option :value="null">-</option>
                  <option v-for="(value, key) in filter_options.statuses" :key="key" :value="value">
                    {{$t("flexben.requests.summary.status." + value)}}
                  </option>
                </select>
              </div>

              <div class="col-12 col-md-4 form-group mb-0" :class="{'offset-md-4':isAdmin}">
                <label for="platform_source" class="col-form-label">{{
                  $t("flexben.requests.filter.field.platform_source") }}</label>
                <select class="form-control w-100" id="platform_source"
                        v-model="params.selected_platform" @change="filterValues()">
                  <option :value="null">-</option>
                  <option v-for="(item, value, index) in filter_options.platform_sources" :key="index" :value="item">
                    {{ item }}
                  </option>
                </select>
              </div>

              <div class="col-12 col-md-4 form-group mb-0" :class="{'offset-md-4':isAdmin}">
                <label for="new_request_start_date" class="col-form-label">{{
                  $t("flexben.requests.filter.field.start_date") }}</label>
                <div class="w-100 d-inline-flex">
                  <label for="new_request_start_date" class="my-auto col-form-label">
                    <font-awesome-icon icon="calendar-alt" class="text-24 mr-2"/>
                  </label>
                  <datepicker :format="dateFormat"
                              :inputClass="'form-control-d m-0 w-100 background-white'"
                              :class="'w-100'"
                              v-model="filters.start_date"
                              @input="filterDate('start_date')"
                              @cleared="filterDate('start_date',true)"
                              :key="2"
                              id="new_request_start_date"></datepicker>
                  <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
                       @click="filterDate('start_date',true)"/>
                </div>
              </div>
              <div class="col-12 col-md-4 form-group mb-0">
                <label for="new_request_start_date" class="col-form-label">{{
                  $t("flexben.requests.filter.field.end_date") }}</label>
                <div class="w-100 d-inline-flex">
                  <label for="new_request_end_date" class="my-auto col-form-label">
                    <font-awesome-icon icon="calendar-alt" class="text-24 mr-2"/>
                  </label>
                  <datepicker :format="dateFormat"
                              :inputClass="'form-control-d m-0 w-100 background-white'"
                              :class="'w-100'"
                              v-model="filters.end_date"
                              @input="filterDate('end_date')"
                              @cleared="filterDate('end_date',true)"
                              :key="2"
                              id="new_request_end_date"></datepicker>
                  <img class="cursor-pointer ml-2" alt="x" src="@/assets/images/flexben/table-remove-button.svg"
                       @click="filterDate('end_date',true)"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-bg-white mt-3">
        <div class="row p-3">
          <div class="col-12">
            <div class="table-responsive scaled-table hover-table">
              <table class="table table-flx table-striped">
                <thead>
                <tr>
                  <td class="cursor-pointer" @click="sortBy('id')">
                    {{ $t("flexben.requests.table.header.number") }}
                    <img src="@/assets/images/arrow-down-fill.svg"
                         :class="{'rotate-180':(sortByField === 'id' && reverseSort)}"/>
                  </td>
                  <td class="cursor-pointer" @click="sortBy('placed_at')">
                    {{ $t("flexben.requests.table.header.placed_on") }}
                    <img src="@/assets/images/arrow-down-fill.svg"
                         :class="{'rotate-180':(sortByField === 'placed_at' && reverseSort)}"/>
                  </td>
                  <td>{{ $t("flexben.requests.table.header.beneficiary") }}</td>
                  <td class="w-120-p">{{ $t("flexben.requests.table.header.beneficiary_cnp") }}</td>
                  <td v-if="isAdmin">{{ $t("flexben.requests.table.header.client") }}</td>
                  <td>{{ $t("flexben.requests.table.header.request_type") }}</td>
                  <td>{{ $t("flexben.requests.table.header.benefit") }}</td>
                  <td>{{ $t("flexben.requests.table.header.partner") }}</td>
                  <td>{{ $t("flexben.requests.table.header.benefit_sub_type") }}</td>
                  <td>{{ $t("flexben.beneficiary_pages.requests.table.budget_type") }}</td>
                  <td>{{ $t("flexben.general.last_modified_at") }}</td>
                  <td class="cursor-pointer" @click="sortBy('total_value')">
                    {{ $t("flexben.requests.table.header.amount") }}
                    <img src="@/assets/images/arrow-down-fill.svg"
                         :class="{'rotate-180':(sortByField === 'total_value' && reverseSort)}"/>
                  </td>
                  <td>{{ $t("flexben.requests.table.header.taxes") }}</td>
                  <td v-if="companySettings.cam_budget_taxation">
                    {{ $t("flexben.beneficiary_pages.requests.table.cam_value") }}
                  </td>
                  <td v-if="companySettings.gross_budget_taxation || companySettings.cam_budget_taxation">
                    {{ $t("flexben.beneficiary_pages.requests.table.gross_amount") }}
                  </td>
                  <td>{{ $t("flexben.requests.table.header.status") }}</td>
                  <td>{{ $t("flexben.requests.table.header.platform_source") }}</td>
                  <td>{{ $t("flexben.requests.table.header.contract_number") }}</td>
                  <td></td>
                </tr>
                </thead>
                <tbody v-if="requests.data.length>0">
                <tr class="trhover parent" v-for="(item, index) in requests.data" :key="item.id">
                  <td>{{ item.id }}</td>
                  <td>{{ moment(item.placed_at, 'DD/MM/YYYY HH:mm') }}</td>
                  <td>{{ item.employee_name }}</td>
                  <td class="w-120-p">{{ item.employee_cnp }}</td>
                  <td v-if="isAdmin">{{ item.company_name }}</td>
                  <td class="text-center">
                    <img v-if="item.benefit_sub_type"
                         :id="'type_tooltip_'+item.id" src="@/assets/images/flexben/order/notLegislated.svg"
                         :alt="$t('flexben.requests.types.'+item.type)"/>
                    <img v-else-if="!item.benefit_sub_type && item.type==='top_up'"
                         :id="'type_tooltip_'+item.id" src="@/assets/images/flexben/order/topUp.svg"
                         :alt="$t('flexben.requests.types.'+item.type)"/>
                    <img v-else-if="!item.benefit_sub_type && item.type==='card_request'"
                         :id="'type_tooltip_'+item.id" src="@/assets/images/flexben/order/cardRequest.svg"
                         :alt="$t('flexben.requests.types.'+item.type)"/>

                    <b-tooltip :target="'type_tooltip_'+item.id" triggers="hover" variant="flexben" placement="topleft">
                      {{ $t('flexben.requests.types.'+ (item.benefit_sub_type ? 'not_legislated' : item.type)) }}
                    </b-tooltip>
                  </td>
                  <td>
                    <span :style="{backgroundColor: item.benefit_color}" class="color-bullet mr-1"></span>
                    {{ item.benefit_name }}
                    <span v-if="item.event !== null"> - {{ (request_history.event.type ==='LEGISLATED') ? $t('flexben.general.legal_events.'+request_history.event.name) : request_history.event.name }}</span>
                  </td>
                  <td>{{ (item.benefit.partner_id!==null) ? item.benefit.partner.name : '-' }}</td>
                  <td>{{ (item.benefit_sub_type) ? $t('flexben.benefit.sub_types.'+item.benefit_sub_type) : '-'}}</td>
                  <td>{{ $t("flexben.beneficiary_pages.requests.budget_types."+item.budget_type) }}</td>
                  <td>{{ moment(item.updated_at,'DD/MM/YYYY HH:mm') }}</td>
                  <td>{{ (item.base_total_value > 0) ? item.base_total_value+' Lei' :'-' }}</td>
                  <td>{{ (item.total_value > 0) ?
                    ((item.all_taxes).toFixed(2)+' Lei') : '-'}}
                  </td>
                  <td v-if="companySettings.cam_budget_taxation">
                    {{ item.value_cam.toFixed(2) }} Lei
                  </td>
                  <td v-if="companySettings.gross_budget_taxation || companySettings.cam_budget_taxation">
                    {{ calculateTotal(item).toFixed(2) }} Lei
                  </td>
                  <td>
                    <span class="beneficiary-request-status"
                          :class="'flx-'+item.status">{{ $t('flexben.requests.summary.status.' + item.status) }}
                    </span>

                    <span :id="'reject_tooltip_'+item.id" v-if="['rejected', 'validation_failed'].includes(item.status)"
                          class="d-inline-block flexben-tooltip-icon flexben-tooltip-icon-red  ml-2">i</span>
                    <b-tooltip :target="'reject_tooltip_' + item.id"
                               variant="flexben"
                               placement="topleft"
                               triggers="hover"
                               v-if="['rejected', 'validation_failed'].includes(item.status)">
                      {{ item.status === 'validation_failed' ? getValidationFailedMessage(item.reject_reason) : getRejectMessage(item.reject_reason) }}
                    </b-tooltip>

                  </td>
                  <td>{{ item.platform_source}}</td>
                  <td>{{ item.contract_number ? item.contract_number : '-' }}</td>
                  <td>
                    <div class="d-flex">

                      <button v-if="item.benefit_sub_type"
                              class="btn action-button" @click="showFieldsModal(index)">
                        {{$t("flexben.general.btn.see_more")}}
                      </button>
                    </div>
                  </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr class="trhover gri-cell">
                  <td :colspan="100" class="text-center">
                    {{$t('flexben.general.no_results_found')}}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-12 p-0">
            <listPagination
              :activePage="requests.current_page"
              :currentPage="requests.current_page"
              :totalItems="requests.total"
              :startItem="requests.from"
              :endItem="requests.to"
              :itemsPerPage="requests.perPage"
              @pageChanged="pageChanged"
              @changePerPage="changePerPage"></listPagination>
          </div>
        </div>
      </div>
    </div>
    <request-dyanmic-fields-modal ref="fields_modal"></request-dyanmic-fields-modal>
    <content-loader :loader-visible="this.loading" :loader-error="loaderError"></content-loader>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben";
import i18n from "@/plugins/language/i18n";
import Datepicker from "vuejs-datepicker";
import {BTooltip} from "bootstrap-vue";
import RequestDyanmicFieldsModal from "./modals/RequestDynamicFieldsModal.vue"


export default {
  name: "RequestsHistoryList",
  components: {
    'datepicker': Datepicker,
    'b-tooltip': BTooltip,
    'request-dyanmic-fields-modal': RequestDyanmicFieldsModal,
  },
  data: function () {
    return {
      initialLoading: true,
      loading: true,
      loaderError: false,
      httpService: httpFlexbenService.requests,
      locale: i18n.locale,
      isAdmin: false,
      // filtering
      dateFormat: 'dd/MM/yyyy',
      filter_options: {
        types: [],
        statuses: [],
        benefits: [],
        companies: [],
        platform_sources: []
      },
      searchString: '',
      reverseSort: false,
      sortByField: null,
      filters: {
        start_date: null,
        end_date: null,
      },
      params: {
        type: null,
        status: null,
        benefit: null,
        company: null,
        beneficiary: null,
        start_date: null,
        end_date: null,
        only_list: null,
        benefit_sub_type: null,
        page: 1,
        perPage: 20,
        sort_by: null,
        sort_direction: null,
        selected_platform: null,
      },
      selectedCompany: {value: null, name: '-'},
      companySettings: {
        cam_budget_taxation: false,
        gross_budget_taxation: false,
        up_dejun_auto: false,
        up_gift_auto: false
      },
      totalCount: 0,
      requests: {
        data: [],
        current_page: 1,
        to: 1,
        perPage: 1,
        lastPage: 1,
        total: 1,
      },
      selectedBenefit: {value: null, name: '-'}
    }
  },
  computed: {
    benefitOptions: function () {
      let returnData = [
        {value: null, name: '-'}
      ];
      let benefitId;
      for (benefitId in this.filter_options.benefits) {
        returnData.push({value: benefitId, name: this.filter_options.benefits[benefitId]});
      }
      return returnData;
    },
    companySelectOptions: function () {
      let returnData = [
        {value: null, name: '-'}
      ];
      let companyId;
      for (companyId in this.filter_options.companies) {
        returnData.push({value: companyId, name: this.filter_options.companies[companyId]});
      }
      return returnData;
    },
  },
  watch: {
    selectedBenefit: {
      handler: function (value) {
        this.params.benefit = value.value;
        this.filterValues();
      }
    },
    selectedCompany: {
      handler: function (value) {
        this.params.company = value.value;
        this.filterValues();
      }
    },
  },
  created() {
    let userRole = this.flxUserLevel().userLevel;
    const employment = this.$store.getters.flx_activeEmployment;
    if (userRole === 'client' && employment != null) {
      this.params.company = employment.company_id;
      this.isAdmin = false;
    } else if (userRole === 'admin') {
      if (typeof localStorage.getItem('flexbenCompanyId') !== "undefined" && localStorage.getItem('flexbenCompanyId') !== null) {
        this.params.company = this.params.company = localStorage.getItem('flexbenCompanyId');
        localStorage.removeItem('flexbenCompanyId');
      }
      this.isAdmin = true;
    } else {
      window.location.href = '/';
      return;
    }
    this._loadList();
  },
  beforeUpdate() {
    this.locale = i18n.locale;
  },
  methods: {
    sortBy(fieldName) {
      if (this.sortByField !== fieldName) {
        this.reverseSort = false;
      }
      this.params.sort_by = fieldName;
      this.params.sort_direction = !this.reverseSort ? 'asc' : 'desc';
      this._loadList(this.params)
      this.sortByField = fieldName;

      this.reverseSort = !this.reverseSort;
    },
    changePerPage(perPage) {
      this.params.page = 1;
      this.params.perPage = perPage;
      this._loadList();
    },
    pageChanged(pageNb) {
      this.params.page = pageNb;
      this._loadList();
    },
    search() {
      if (this.searchString.length >= 3) {
        this.resetFilters();
        this.params.search = this.searchString;
        this._loadList();
      } else if (this.searchString.length === 0) {
        this.resetFilters();
        this.params.search = this.searchString;
        this._loadList();
      }
    },
    filterValues() {
      this._loadList();
    },
    filterDate(key, clearDate = false) {
      if (clearDate) {
        this.filters[key] = null;
        this.params[key] = null;
      } else {
        this.params[key] = this.moment(this.filters[key], 'YYYY-MM-DD');
      }
      this._loadList();
    },
    resetFilters() {
      this.params.sub_type = null;
      this.params.type = null;
      this.params.status = null;
      this.params.benefit = null;
      this.params.company = null;
      this.params.beneficiary = null;
      this.params.start_date = null;
      this.params.end_date = null;
      this.params.only_list = null;
      this.params.benefit_sub_type = null;
      this.params.selected_platform = null;
    },
    getRejectMessage(obj) {
      if (obj === null || (obj.message === null || obj.message.length === 0)) {
        return this.$t('flexben.general.no_reason_specified');
      }

      return obj.message;
    },
    getValidationFailedMessage(obj){
      if( typeof obj.message == 'undefined' || obj.message == null){
        return this.$t('flexben.up_ro_validation_messages.UNKNOWN');
      }
      let message = '', description = '';

      message = obj.message.replaceAll('.', '_');

      if ( typeof obj.description != 'undefined' || obj.description != null ){
        description = obj.description;
      }

      return this.$t('flexben.up_ro_validation_messages.'+message+'')+' '+description;
    },
    getTypeImage(request) {
      if (request.benefit_sub_type !== null) {
        return 'notLegislated.svg';
      } else {
        if (request.type === 'top_up') {
          return 'topUp.svg';
        } else {
          return 'cardRequest.svg';
        }
      }
    },
    calculateTotal(item) {
      let returnVal = item.total_value;
      if (item.is_gross_taxed) {
        returnVal += item.total_value_cas + item.total_value_cass + item.total_value_tax;
      }
      if (item.is_cam_in_value) {
        returnVal += item.value_cam
      }
      return returnVal;
    },
    _loadList() {
      this.loading = true;
      this.httpService.history(this.params)
        .then(function (response) {
          this.requests.data = response.data.list.data;
          this.requests.current_page = response.data.list.current_page;
          this.requests.to = response.data.list.to;
          this.requests.perPage = response.data.list.perPage;
          this.requests.lastPage = response.data.list.lastPage;
          this.requests.total = response.data.list.total;
          this.filter_options = response.data.filters;
          this.companySettings = response.data.company_settings;
          if (this.initialLoading) {
            this.totalCount = response.data.total_count;
            this.initialLoading = false;
            this.params.only_list = true;
          }
          this.loading = false;
        }.bind(this))
        .catch(function (error) {
          this.loaderError = true;
          this.loading = false;
        }.bind(this));
    },
    showFieldsModal(index) {
      let request = this.requests.data[index];
      this.$refs['fields_modal'].openModal(request.metas, request.contract_number, request.benefit_voucher, request.world_class_order_request, request.benefit_sub_type);
    },
  }
}
</script>
