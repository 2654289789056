<template>
  <div v-show="!initialLoading" class="col-12">
    <div class="container-bg-white">
      <div class="row p-3" id="filterGroup">
        <div class="col-12 col-xl-3 mb-2 mb-xl-0">
          <img src="@/assets/images/filter.svg"/>
          <span class="ml-3">{{$t("flexben.general.filter")}}</span>
        </div>
        <div class="col-12 col-xl-9">
          <div class="row">
            <div class="col-12 col-md-4 form-group mb-0">
              <label for="start_date" class="my-auto mr-2 col-form-label">{{ $t("flexben.reports.inputLabels.startDate") }}
                <font-awesome-icon icon="calendar-alt" class="text-24"/>
              </label>
              <div class="w-100 d-inline-flex">
                <vue-datepicker :format="dateFormat"
                                :inputClass="'form-control-d m-0 w-100 background-white'"
                                v-model="modelData.startDate"
                                full-month-name
                                minimum-view="month"
                                :class="'w-100'"
                                clear-button-icon
                                key="start_date"
                                id="start_date">
                </vue-datepicker>
              </div>
            </div>
            <div class="col-12 col-md-4 form-group mb-0">
              <label for="end_date" class="my-auto mr-2 col-form-label">{{ $t("flexben.reports.inputLabels.endDate") }}
                <font-awesome-icon icon="calendar-alt" class="text-24"/>
              </label>
              <div class="w-100 d-inline-flex">
                <vue-datepicker :format="dateFormat"
                                :inputClass="'form-control-d m-0 w-100 background-white'"
                                v-model="modelData.endDate"
                                full-month-name
                                minimum-view="month"
                                :class="'w-100'"
                                clear-button-icon
                                key="end_date"
                                id="end_date">
                </vue-datepicker>
              </div>
            </div>
            <div class="col-12 col-md-4 mb-0" v-if="isAdmin">
              <label for="select_company" class="col-form-label">{{ $t("flexben.reports.filters.client") }}</label>
              <select class="form-control" v-model="modelData.company"  id="company">
                <option v-for="company in filterOptions.companies" :key="company" :value="company">
                  {{company.name}}
                </option>
              </select>
            </div>
            <div class="col-12 col-md-4 mb-0" v-if="isAdmin">
              <label for="select_budget" class="col-form-label">{{ $t("flexben.reports.filters.budget") }}</label>
              <select class="form-control" v-model="modelData.budget"  id="budget">
                <option v-for="budget in filterOptions.budgets" :key="budget" :value="budget">
                  {{budget}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Datepicker from "vuejs-datepicker";
import httpFlexbenService from "@/http/http-flexben";
import moment from "moment";
import utils from "@/plugins/utils";

export default {
  name: "MonthlyBudgetReport",
  components: {
    'vue-datepicker': Datepicker,
  },
  props: {
    isAdmin: {
      required: true,
      default: false
    },
    company: {
      required: true,
      default: null
    },
  },
  data: function () {
    return {
      initialLoading: true,
      httpService: httpFlexbenService.reports.monthly_budget,
      dateFormat: 'MM/yyyy',
      momentFormat: 'YYYY-MM',
      filterOptions: {
        companies: [],
        budgets: []
      },
      modelData: {
        startDate: null,
        endDate: null,
        companyId: null,
        companyName: null,
        budget: null,
        lang: this.$store.state.language
      },
      errors: {
        show: false,
        message: null,
      }
    }
  },
  watch: {
    'modelData.startDate': {
      handler: function (val) {
        console.log(val)
        this.modelData.startDate =moment(val).format(this.momentFormat);
      }
    },
    'modelData.endDate': {
      handler: function (val) {
        this.modelData.endDate = moment(val).format(this.momentFormat);
      }
    },
    'modelData.company': {
      handler: function (val) {
        this.modelData.companyId = val.id;
        this.modelData.companyName = val.name;
      }
    },

  },
  created() {
    if (this.isAdmin) {
      this._getOptions()
    } else {
      this.modelData.companyId = this.company;
      this.initialLoading = false;
      this._toggleLoading(false);
    }
  },
  methods: {
    _getOptions() {
      this._toggleLoading(true);
      this.httpService.getOptions()
        .then((response) => {
          this.filterOptions.companies = response.data.companies;
          this.filterOptions.budgets = response.data.budgets;
          this.initialLoading = false;
          this._toggleLoading(false);
        })
        .catch((error) => {
          this._toggleLoading(false, this.$t('flexben.general.server_error'));
        });
    },
    _validateFilters() {
      this.errors.show = false;
      this.errors.message = null;
      if (this.modelData.startDate === null) {
        this.errors.show = true;
        this.errors.message = this.$t('flexben.reports.errors.no_start_date_selected_monthly_budget');
      }

      if (this.modelData.endDate === null) {
        this.errors.show = true;
        this.errors.message = this.$t('flexben.reports.errors.no_end_date_selected_monthly_budget');
      }

      if (this.modelData.companyId === null) {
        this.errors.show = true;
        this.errors.message = this.$t('flexben.reports.errors.no_client_selected');
      }

      this.$emit('setErrors', this.errors.show, this.errors.message);
    },
    download() {
      this._validateFilters();
      if (this.errors.show) {
        return;
      }

      let payload = {...this.modelData}
      payload.lang = this.$store.state.language;

      this._toggleLoading(true);
      this.httpService.download(payload)
        .then((response) => {
          if (response.data.success === false) {
            this._toggleLoading(false);
            return;
          }

          utils.generateDownloadFromReponse(response);
          this._toggleLoading(false);
        })
        .catch((error) => {
          this._toggleLoading(false, this.$t('flexben.general.server_error'));
        });
    },
    _toggleLoading(loading, loaderError = null) {
      this.$emit('toggleLoading', loading, loaderError);
    }
  },
}
</script>

<style scoped>

</style>
